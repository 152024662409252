import React from 'react'

import AttractionIcon from '/public/static/images/attraction-icon-red.svg'
import GuideIcon from '/public/static/images/guide-icon-blue.svg'
import ExperienceIcon from '/public/static/images/smiley-icon-green.svg'

import theme from 'common/helpers/theme'

import * as S from './Styled'

const DestinationCard = ({image, slug, title, experienceCount, attractionCount, guideCount}) => {
  return (
    <S.CardWrapper href={`/destination/${slug}`}>
      <S.ImageWrapper src={image.path} />
      <S.TextWrapper>
        <S.Title>{title}</S.Title>
        <S.EntityInfoWrapper>
          {experienceCount > 0 && (
            <S.EntityInfo>
              <ExperienceIcon />
              <S.Text color={theme.colors.primary4}>{experienceCount} </S.Text>
            </S.EntityInfo>
          )}
          {attractionCount > 0 && (
            <S.EntityInfo>
              <AttractionIcon />
              <S.Text color={theme.colors.primary2}>{attractionCount}</S.Text>
            </S.EntityInfo>
          )}
          {guideCount > 0 && (
            <S.EntityInfo>
              <GuideIcon />
              <S.Text color={theme.colors.primary1}>{guideCount}</S.Text>
            </S.EntityInfo>
          )}
        </S.EntityInfoWrapper>
      </S.TextWrapper>
    </S.CardWrapper>
  )
}

export default DestinationCard
