import styled from 'styled-components'

export const CardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  text-decoration: none;
`

export const ImageWrapper = styled.img`
  height: 430px;
  border-radius: 12px;
  background: lightgray 50% / cover no-repeat, #253044;
  max-width: 287px;
  align-self: stretch;
  object-fit: cover;

  ${props => props.theme.mediaQuery.md} {
    height: 404px;
    max-width: 270px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
`

export const Title = styled.div`
  color: ${props => props.theme.colors.secondary10};
  font-size: 18px;
  letter-spacing: -0.36px;
`

export const EntityInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

export const EntityInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Text = styled.div`
  color: ${props => props.color};
  font-size: 14px;
  letter-spacing: -0.28px;
`
