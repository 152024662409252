import {useRouter} from 'next/router'
import {useTranslation} from 'react-i18next'
import {ATTRACTION, EXPERIENCE, GUIDE} from 'routes.json'

import {getCurrentURLQuery, resolveCurrentLanguage} from 'common/helpers'
import {useLazyGetHomeDataQuery} from 'lib/api/backend/home'
import {useGenerateModel} from 'lib/model/index'

import {ENTITIES} from '../../consts.json'

import {getFormattedLocation} from './helpers'
import {prepareDestinations, prepareExperiences, prepareGuides} from './utils'

const guideLinkBase = GUIDE.VIEW.link
const experienceLinkBase = EXPERIENCE.VIEW.link
const attractionLinkBase = ATTRACTION.VIEW.link
const EXPERIENCE_LIMIT = 6
const EXPERIENCE_OFFSET = 0
const GUIDES_LIMIT = 8
const GUIDES_OFFSET = 0
const ATTRACTIONS_LIMIT = 8
const ATTRACTIONS_OFFSET = 0

function useModel(props) {
  const {t} = useTranslation()
  const router = useRouter()
  const [getHomeData] = useLazyGetHomeDataQuery()

  const {model, ModelProvider} = useGenerateModel({
    loading: false,
    homeData: props.initialHomeData,
    experiences: props.initialHomeData?.experiences?.items
      ? prepareExperiences(props.initialHomeData?.experiences?.items, t)
      : [],
    experiencesTotal: props.initialHomeData?.experiences?.total,
    guides: props.initialHomeData?.guides?.items
      ? prepareGuides(props.initialHomeData?.guides?.items, t)
      : [],
    guidesTotal: props.initialHomeData?.guides?.total,
    attractions: props.initialHomeData?.attractions?.items
      ? props.initialHomeData?.attractions?.items.map(item => ({
          ...item,
          src: item.image
        }))
      : [],
    attractionsTotal: props.initialHomeData?.attractions?.total,
    destinations: props.initialHomeData?.destinations
      ? prepareDestinations(props.initialHomeData?.destinations)
      : [],
    title: `${t('HOME_HEADER_TITLE_1')} ${t('HOME_HEADER_TITLE_2')}`,
    seeAllQueryParams:
      router.query.searchEntityName &&
      router.query.searchEntityName &&
      router.query.searchEntityName
        ? // eslint-disable-next-line max-len
          `searchEntityName=${router.query.searchEntityName}&searchEntityId=${router.query.searchEntityId}&searchEntityType=${router.query.searchEntityType}`
        : null,
    searchPhrase: router.query.searchEntityName || null,
    initialEntityType: router.query.searchEntityType || null,
    location: props.initialLocation,
    handleTitleChange(item) {
      this.title =
        item &&
        ![ENTITIES.GUIDE, ENTITIES.EXPERIENCE, ENTITIES.ATTRACTION].includes(item.entityType)
          ? `${t('HOME_HEADER_TITLE_1')} - <1>${item.title.split(',')[0]}</1>`
          : `${t('HOME_HEADER_TITLE_1')} ${t('HOME_HEADER_TITLE_2')}`
    },
    handleSearchClear() {
      this.loading = true
      this.handleTitleChange()
      this.setURLParams()
      this.location = null
      this.searchPhrase = null
      this.initialEntityType = null
      this.seeAllQueryParams = null
      this.setHomeData()
    },
    onItemClick(item) {
      this.loading = true
      if (item.entityType === ENTITIES.GUIDE) {
        const itemSlug = item.slug
        const prettyLink = `${guideLinkBase}/${itemSlug}`
        window.open(prettyLink, '_self')
      } else if (item.entityType === ENTITIES.EXPERIENCE) {
        const itemSlug = item.slug
        const prettyLink = `${experienceLinkBase}/${itemSlug}`
        window.open(prettyLink, '_self')
      } else if (item.entityType === ENTITIES.ATTRACTION) {
        const itemSlug = item.slug
        const prettyLink = `${attractionLinkBase}/${itemSlug}`
        window.open(prettyLink, '_self')
      } else {
        const query = {
          ...getCurrentURLQuery(),
          searchEntityName: item.title,
          searchEntityId: item.id,
          searchEntityType: item.entityType
        }
        this.setURLParams(query)
        // eslint-disable-next-line max-len
        this.seeAllQueryParams = `searchEntityName=${item.title}&searchEntityId=${item.id}&searchEntityType=${item.entityType}`
      }
      this.location = getFormattedLocation(item)
      this.handleTitleChange(item)
      this.setHomeData()
    },
    setURLParams(params) {
      const urlParams = new URLSearchParams(params)
      window.history.replaceState(
        window.history.state,
        '',
        [...urlParams].length > 0 ? '?' + urlParams.toString() : router.route
      )
    },
    setHomeData() {
      let requestData = {
        languageId: resolveCurrentLanguage(),
        experienceLimit: EXPERIENCE_LIMIT,
        experienceOffset: EXPERIENCE_OFFSET,
        guidesLimit: GUIDES_LIMIT,
        guidesOffset: GUIDES_OFFSET,
        attractionsLimit: ATTRACTIONS_LIMIT,
        attractionsOffset: ATTRACTIONS_OFFSET
      }

      if (this.location) {
        requestData.cityId = this.location.cityId
        requestData.countryId = this.location.countryId
        requestData.regionId = this.location.regionId
      }

      getHomeData(requestData, true).then(newHomeData => {
        this.homeData = newHomeData?.data
        const preparedExperiences = newHomeData?.data?.experiences?.items
          ? prepareExperiences(newHomeData?.data?.experiences?.items, t)
          : []
        this.experiences = preparedExperiences
        this.experiencesTotal = newHomeData?.data?.experiences?.total

        const preparedGuides = newHomeData?.data?.guides?.items
          ? prepareGuides(newHomeData?.data?.guides?.items, t)
          : []
        this.guides = preparedGuides
        this.guidesTotal = newHomeData?.data?.guides?.total

        this.attractions = newHomeData?.data?.attractions?.items
          ? newHomeData?.data?.attractions?.items.map(item => ({
              ...item,
              src: item.image
            }))
          : []
        this.attractionsTotal = newHomeData?.data?.attractions?.total

        const preparedDestinations = newHomeData?.data?.destinations
          ? prepareDestinations(newHomeData?.data?.destinations)
          : []
        this.destinations = preparedDestinations
        this.loading = false
      })
    }
  })
  return {model, ModelProvider, router}
}

export default useModel
