import styled from 'styled-components'

import {HEADER} from 'consts.json'

const SEARCH_TOP_OFFSET = 24
const PRIMARY_COLOR = '#14181C'
const GRADIENT_MIN_HEIGHT = 82

export const Wrapper = styled.div`
  padding: 0 24px;
  font-family: 'Nunito Sans', sans-serif;
  position: relative;

  ${props => props.theme.mediaQuery.md} {
    padding: 0 32px 88px 32px;
  }
`
export const HeaderGradient = styled.div`
  top: ${HEADER.DEFAULT_HEIGHT}px;
  left: 0;
  background: ${props => (props.headerGradient ? props.headerGradient : 'transparent')};
  width: 100%;
  height: ${props => props.height - HEADER.DEFAULT_HEIGHT}px;
  min-height: ${props =>
    props.height - HEADER.DEFAULT_HEIGHT < GRADIENT_MIN_HEIGHT ? '80px' : ''};
  position: fixed;

  ${props => props.theme.mediaQuery.md} {
    height: ${props => props.height}px;
  }
`

export const TransparentGradient = styled.div`
  top: ${HEADER.DEFAULT_HEIGHT}px;
  left: 0;
  position: fixed;
  width: 100%;
  background: blue;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.3)),
    to(rgba(0, 0, 0, 0))
  );
  z-index: 9;
  height: ${props => props.height - HEADER.DEFAULT_HEIGHT}px;
  min-height: ${props =>
    props.height - HEADER.DEFAULT_HEIGHT < GRADIENT_MIN_HEIGHT ? '80px' : ''};

  ${props => props.theme.mediaQuery.md} {
    height: ${props => props.height}px;
  }
`

export const InnerColoredGradient = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(24, 157, 215);
  background: linear-gradient(
    90deg,
    rgba(24, 157, 215, 1) 0%,
    rgba(250, 169, 41, 1) 25%,
    rgba(200, 40, 83, 1) 50%,
    rgba(219, 88, 17, 1) 74%,
    rgba(101, 159, 23, 1) 100%
  );
`

export const InnerWrapper = styled.div`
  max-width: 1152px;
  margin: auto;
  width: 100%;
`

export const TitleWrapper = styled.div`
  padding: 56px 24px 48px 0;

  ${props => props.theme.mediaQuery.md} {
    padding: 80px 0 72px 0;
  }
`

export const Title = styled.h1`
  font-weight: normal;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: ${PRIMARY_COLOR};
  margin: 0;
  position: relative;
  z-index: 999;

  ${props => props.theme.mediaQuery.md} {
    font-size: 46px;
    line-height: 63px;
  }
`
export const TitleBold = styled.span`
  font-weight: 900;
`

export const SearchSectionWrapper = styled.div`
  position: sticky;
  top: 0;
  margin-bottom: 24px;
  top: ${HEADER.DEFAULT_HEIGHT + SEARCH_TOP_OFFSET}px;
  z-index: 99;

  ${props => props.theme.mediaQuery.md} {
    margin-bottom: 32px;
  }
`

export const ContentWrapper = styled.div``

export const SearchContentWrapper = styled.div`
  position: relative;
`
