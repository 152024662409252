import React from 'react'

import withTranslation from 'common/HOCs/withTranslation'
import {getSeoConfig, resolveCurrentLanguage} from 'common/helpers'
import HomeComponent from 'components/CPHome'
import CPLayout from 'components/CPLayout'
import {HEADER} from 'consts.json'
import {getHomeData, getRunningOperationPromises} from 'lib/api/backend/home'
import {wrapper} from 'lib/store'
import {getHomeData as getInitialHomeData} from 'services/homeService'

import {getFormattedLocation} from '../components/CPHome/helpers'

const EXPERIENCE_LIMIT = 6
const EXPERIENCE_OFFSET = 0
const GUIDES_LIMIT = 8
const GUIDES_OFFSET = 0
const ATTRACTIONS_LIMIT = 8
const ATTRACTIONS_OFFSET = 0

const footerStyle = {
  marginTop: 0
}

const Home = ({seoConfig, initialHomeData, initialLocation}) => {
  return (
    <CPLayout
      seoConfig={seoConfig}
      key={undefined}
      showFooter
      showLangDropdown={true}
      header={{appearance: HEADER.APPEARANCES.NORMAL}}
      footerStyle={footerStyle}
      customFooterWrapperStyle={footerStyle}
    >
      <HomeComponent initialHomeData={initialHomeData} initialLocation={initialLocation} />
    </CPLayout>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(store => async ctx => {
  const {searchEntityType, searchEntityId} = ctx.query
  const lang = resolveCurrentLanguage(ctx.req)

  const seoConfig = getSeoConfig({
    ctx,
    title: 'SEO_HOME',
    description: 'HOME_PAGE_META_TAG_DESCRIPTION'
  })

  const location = getFormattedLocation({
    id: searchEntityId,
    entityType: searchEntityType
  })

  store.dispatch(
    getHomeData.initiate({
      languageId: lang,
      experienceLimit: EXPERIENCE_LIMIT,
      experienceOffset: EXPERIENCE_OFFSET,
      guidesLimit: GUIDES_LIMIT,
      guidesOffset: GUIDES_OFFSET,
      attractionsLimit: ATTRACTIONS_LIMIT,
      attractionsOffset: ATTRACTIONS_OFFSET
    })
  )

  const requestData = {
    lang,
    experienceLimit: EXPERIENCE_LIMIT,
    experienceOffset: EXPERIENCE_OFFSET,
    guidesLimit: GUIDES_LIMIT,
    guidesOffset: GUIDES_OFFSET,
    attractionsLimit: ATTRACTIONS_LIMIT,
    attractionsOffset: ATTRACTIONS_OFFSET
  }

  if (location.cityId) {
    requestData.cityId = location.cityId
  }

  if (location.regionId) {
    requestData.regionId = location.regionId
  }

  if (location.countryId) {
    requestData.countryId = location.countryId
  }

  if (requestData.cityId || requestData.regionId || requestData.countryId) {
    store.dispatch(getHomeData.initiate(requestData))
  }

  let [initialHomeData] = await Promise.all([
    getInitialHomeData(requestData),
    getRunningOperationPromises()
  ])

  return {props: {seoConfig, initialHomeData, initialLocation: location}}
})

export default withTranslation(Home)
